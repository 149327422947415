export const CommandResultsLoading = () => {
  return (
      <div className="absolute w-full bg-white border rounded border-gray-500 px-4">
        <h2 className="text-lg font-display my-1 mt-4 text-gray-800 font-semibold">Loading...</h2>
        <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-4 animate-pulse">
          <div className="row-span-6 pr-4 my-4">
            <h3 className="bg-gray-400 rounded border-b border-b-navy-600 mb-4">&nbsp;</h3>
            <ul className="lg:max-h-80 lg:overflow-y-auto">
              <li value="completion" className="block group w-full">
                <p className="bg-gray-400 rounded m-0 p-1 mb-1">&nbsp;</p> 
              </li>
              <li value="completion" className="block group w-full">
                <p className="bg-gray-400 rounded m-0 p-1 mb-1">&nbsp;</p> 
              </li>
              <li value="completion" className="block group w-full">
                <p className="bg-gray-400 rounded m-0 p-1 mb-1">&nbsp;</p> 
              </li>
              <li value="completion" className="block group w-full">
                <p className="bg-gray-400 rounded m-0 p-1 mb-1">&nbsp;</p> 
              </li>
              <li value="completion" className="block group w-full">
                <p className="bg-gray-400 rounded m-0 p-1 mb-1">&nbsp;</p> 
              </li>
              <li value="completion" className="block group w-full">
                <p className="bg-gray-400 rounded m-0 p-1 mb-1">&nbsp;</p> 
              </li>
            </ul>
          </div>
          <div className="row-span-6 pr-4 my-4">
            <h3 className="bg-gray-400 rounded border-b border-b-navy-600 mb-4">&nbsp;</h3>
            <ul className="lg:max-h-80 lg:overflow-y-auto">
              <li value="completion" className="block group w-full">
                <p className="bg-gray-400 rounded m-0 p-1 mb-1">&nbsp;</p> 
              </li>
              <li value="completion" className="block group w-full">
                <p className="bg-gray-400 rounded m-0 p-1 mb-1">&nbsp;</p> 
              </li>
              <li value="completion" className="block group w-full">
                <p className="bg-gray-400 rounded m-0 p-1 mb-1">&nbsp;</p> 
              </li>
              <li value="completion" className="block group w-full">
                <p className="bg-gray-400 rounded m-0 p-1 mb-1">&nbsp;</p> 
              </li>
              <li value="completion" className="block group w-full">
                <p className="bg-gray-400 rounded m-0 p-1 mb-1">&nbsp;</p> 
              </li>
              <li value="completion" className="block group w-full">
                <p className="bg-gray-400 rounded m-0 p-1 mb-1">&nbsp;</p> 
              </li>
            </ul>
          </div>
          <div className="row-span-6 pr-4 my-4">
            <h3 className="bg-gray-400 rounded border-b border-b-navy-600 mb-4">&nbsp;</h3>
            <ul className="lg:max-h-80 lg:overflow-y-auto">
              <li value="completion" className="block group w-full">
                <p className="bg-gray-400 rounded m-0 p-1 mb-1">&nbsp;</p> 
              </li>
              <li value="completion" className="block group w-full">
                <p className="bg-gray-400 rounded m-0 p-1 mb-1">&nbsp;</p> 
              </li>
              <li value="completion" className="block group w-full">
                <p className="bg-gray-400 rounded m-0 p-1 mb-1">&nbsp;</p> 
              </li>
              <li value="completion" className="block group w-full">
                <p className="bg-gray-400 rounded m-0 p-1 mb-1">&nbsp;</p> 
              </li>
              <li value="completion" className="block group w-full">
                <p className="bg-gray-400 rounded m-0 p-1 mb-1">&nbsp;</p> 
              </li>
              <li value="completion" className="block group w-full">
                <p className="bg-gray-400 rounded m-0 p-1 mb-1">&nbsp;</p> 
              </li>
            </ul>
          </div>
        </div>
      </div>
  )
}