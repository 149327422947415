'use client'
import { useState } from "react"
import { CommandSearchResults } from "./CommandSearchResults.client"

export const CommandSearch = ({slugBase}: {
  slugBase: string
}) => {

  const [query, setQuery] = useState('')
  const [forceShow, setForceShow] = useState(false)

  return (
      <>
        <div className="flex gap-2 items-center py-2">
          <input 
            id="commandSearch"
            autoComplete="off"
            className="w-full font-display text-xl bg-white p-3 rounded-lg border-gray-500 border placeholder-gray-60 placeholder:italic"
            placeholder="Search for a command E.g. pull:database"
            onChange={(event) => setQuery(event.target.value)}
            onFocus={() => setForceShow(true)}
            onBlur={() => setTimeout(() => setForceShow(false), 150)}
             />
        </div>
        <div className="relative w-full z-10 pr-4">
          {forceShow && (
            <div className={`absolute w-full bg-white border rounded border-gray-500 p-4 pt-0`}>
                <CommandSearchResults query={query} slugBase={slugBase} />
            </div>
          )}
        </div>
      </>
  )
}