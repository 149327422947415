'use client'
import Mark from "mark.js"
import { ReactNode, useEffect, useId } from "react"

export const SearchHighlight = ({ query, children, className }: {
  query?: string
  className?: string
  children: ReactNode
}) => {

  const id = useId()

  useEffect(() => {
    if (!query) {
      return
    }
    //highlighting searched terms
    const markInstance = new Mark(document.getElementById(id)!);
    markInstance.unmark({
      done: () => {
        markInstance.mark(query, { accuracy: "partially", separateWordSearch: false });
      }
    });
  }, [query, id])

  if (!query) {
    return <>{children}</>
  }

  return <div id={id} className={className}>{children}</div>
}