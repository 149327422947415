'use client'
import { CodeHighlight } from "@/components/wysiwyg/CodeHighlight.client";
import { Command } from "@/lib/acli/backend"
import Markdown from 'react-markdown'

function processSymfonyOutput(text: string) {
  return  text
    .replaceAll(/<info>(.*)<\/info>/g, '`$1`')
    .replaceAll(/<comment>(.*)<\/comment>/g, '`$1`')
    .replaceAll('<info>', '')
    .replaceAll('</info>', '')
    .replaceAll('<comment>', '')
    .replaceAll('</>', '')
}

export const CommandHelp = (props: Command) => {
  return (
    <article className="meta-content transition-colors duration-300">
      <h2 className="hidden font-display text-2xl">{props.name}</h2>
      <p className="text-xl">{processSymfonyOutput(props.description)}</p>
      <Markdown components={{
        h2: (props) => <h2 className="text-lg font-display my-1 mt-4 text-gray-800 font-semibold">{props.children}</h2>,
        code: (props) => typeof props.children == "string" ? <CodeHighlight className="rounded" language="bash">{props.children}</CodeHighlight> : <code>{props.children}</code>
      }}>{processSymfonyOutput(props.help)}</Markdown>

      <div className="bg-white p-4 shadow my-4">
        <h3 className="m-0 mb-2">Usage</h3>
        <code className="p-4 rounded bg-gray-300 text-navy-600 pl-10 block flex flex-col gap-2 overflow-x-auto">
          {props.usage.map((example, i) => {
            return <pre className="" key={i}>acli {example}</pre>
          })}
        </code>
      </div>
 
      
      {(Object.values(props.definition.arguments).length > 0) && (
        <>
          <h3>Arguments</h3>
          <ul>
            {Object.values(props.definition.arguments).map(arg => {
              return (
                <li key={arg.name} className="pl-10 mb-4 grid grid-cols-4 gap-4 hover:bg-white rounded">
                  <h4 className="">{arg.name}</h4>
                  <div className="col-span-3">
                    <h3 className="flex gap-1">
                    <span className="font-normal italic text-sm">{arg.is_required ? 'Required' : 'Optional'}</span>
                    </h3>
                    
                    <p>{arg.description}</p>
                    {arg.default !== null && (
                      <p>Defaults to <code>{JSON.stringify(arg.default)}</code></p>
                    )}
                  </div>
                </li>
              )
            })}
          </ul>
        </>
      )}

      {(Object.values(props.definition.options).length > 0) && (
        <>
          <h3>Options</h3>
          <ul>
            {Object.values(props.definition.options).map(opt => {
              return (
                <li key={opt.name} className="pl-10 mb-4 grid grid-cols-4 gap-4 hover:bg-white rounded">
                  <h4>
                    <span>{opt.name}<span className="font-normal">{(opt.shortcut.length > 0) && (<> | {opt.shortcut}</>)}</span></span>
                  </h4>
                  <div className="col-span-3">
                    <h5 className="flex gap-1">
                    <span className="font-normal italic text-sm">{opt.is_value_required ? 'Required' : 'Optional'}</span>
                    {!opt.accept_value && <span data-icon="flag" className="after:bg-navy-600 after:hover:bg-blue-600" title="This option is a flag. No value needs to be provided." />}
                    </h5>
                    
                    <p>{processSymfonyOutput(opt.description)}</p>
                    {opt.default !== null && (
                      <p>Defaults to <code>{JSON.stringify(opt.default)}</code></p>
                    )}
                  </div>
                </li>
              )
            })}
          </ul>
        </>
      )}
      
    </article>
  )
}