'use client'
import { AcquiaCliResource, CommandNamespace } from "@/lib/acli/backend"
import Link from "next/link"
import { SearchHighlight } from "@/components/app/search/SearchHighlight.client"
import { CommandResultsLoading } from "./CommandResultsLoading"
import { useContentFromRoute } from "@/contexts/ContentRouteProvider"
import { DrupalNodeExternalResource } from "@/types"

export function CommandSearchResults({query, slugBase}: {
  query: string
  slugBase: string
}) {

  const { external } = useContentFromRoute<DrupalNodeExternalResource, AcquiaCliResource>()

  if (!external) {
    return <CommandResultsLoading />
  }

  const { index } = external 

  const results = index.filter(c => c.command.includes(query))

  const groupedCommands:{
    [key: string]: CommandNamespace[]
  } = {}

  results.forEach((cmd) => {
    const group = cmd.command.includes(':') ? cmd.command.split(':')[0] : 'global'
    if (!groupedCommands[group]) {
      groupedCommands[group] = []
    }
    groupedCommands[group].push(cmd)
  })

  'row-span-1 row-span-2 row-span-3 row-span-4 row-span-5 row-span-6 row-span-7'

  return Object.keys(groupedCommands).length > 0 && (
      <>
          <h2 className="text-lg font-display my-1 mt-4 text-gray-800 font-semibold">Commands</h2>
            <SearchHighlight query={query}>
            <div className="lg:columns-2 xl:columns-3">
            {Object.keys(groupedCommands).map((group) => (
              <div key={group} className={`pr-4 my-4`}>
                <h3 className="font-bold text-navy-600 border-b border-b-navy-600 mb-4">{group}</h3>
                <ul className="">
                  {groupedCommands[group].map((cmd) => (
                    <li 
                      key={cmd.command} 
                      value={cmd.command} 
                      className={`font-semibold m-0 mb-1`}
                      >
                          <Link
                            className={`block p-1 mb-1 hover:bg-gray-400 rounded text-blue-700`}
                            title={cmd.usage}
                            href={slugBase + '/' + cmd.command}
                            >
                            {cmd.command}
                          </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            </div>
            </SearchHighlight>
      </>
    )
}